<template>
	<Dialog :dialog="serverDialog" :dialog-width="dialog_width" @close="$emit('close', true)">
		<template v-slot:title> Renew Server </template>
		<template v-slot:body>
			<v-layout class="px-4">
				<v-form v-model="formValid" @submit="submit">
					<v-row>
						<v-col cols="12" lg="12" md="12">
							<label for="server-cost" class="required">Cost</label>
							<NumberInput
								id="server-cost"
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								style="margin-top: 0px !important"
								placeholder="Cost"
								v-model="server.cost"
								prependInnerIcon="mdi-currency-usd"
								:rules="[vrules.required(server.cost, 'Cost')]"
								:class="{
									required: !server.cost,
								}"
							></NumberInput>
						</v-col>

						<v-col cols="12" lg="12" md="12">
							<label for="renew-date" class="required">Renew Date</label>
							<DatePicker
								:hideTopMargin="true"
								depressed
								required
								:mandatory="true"
								:min-date="minRenewDate"
								:loading="pageLoading"
								placeholder="Renew Date"
								v-model="server.renew_date"
								:rules="[vrules.required(server.renew_date, 'Renew Date')]"
								:class="{
									required: !server.renew_date,
								}"
							></DatePicker>
						</v-col>

						<v-col cols="12" lg="12" md="12">
							<label for="payment-date" class="required">Payment Date</label>
							<DatePicker
								:hideTopMargin="true"
								depressed
								:mandatory="true"
								:loading="pageLoading"
								placeholder="Payment Date"
								clearable
								required
								v-model="server.payment_date"
								:rules="[vrules.required(server.payment_date, 'Payment Date')]"
								:class="{
									required: !server.payment_date,
								}"
							></DatePicker>
						</v-col>

						<v-col cols="12" lg="12" md="12">
							<label for="server-name">Remark</label>
							<TextAreaInput
								hide-details
								dense
								style="margin-top: 0px !important"
								:disabled="pageLoading"
								:loading="pageLoading"
								placeholder="Remark"
								:word-limit="200"
								v-model="server.remark"
							></TextAreaInput>
						</v-col>

						<v-col cols="12" lg="12" md="12" class="my-auto py-0">
							<label class="input-label mb-1">Attachment(s)</label>
							<template v-for="(file, index) in files">
								<v-row :key="index" :class="{ 'mt-3': index > 0 }">
									<v-col lg="5" cols="12" class="my-auto py-0 d-flex align-items-center">
										<v-file-input
											:id="`document-file-${index}`"
											placeholder="Select File"
											outlined
											prepend-icon=""
											prepend-inner-icon="mdi-attachment"
											hide-details
											v-model="file.file"
											@change="updateFile(index, $event)"
											@click:clear="updateFile(index, $event)"
										></v-file-input>
									</v-col>
									<v-col lg="5" cols="12" class="my-auto py-0 d-flex align-items-center">
										<TextInput
											:id="`document-name-${index}`"
											v-model="file.name"
											hide-details
											hideTopMargin
											placeholder="File Name"
											:suffix="file.suffix"
										></TextInput>
									</v-col>
									<v-col
										lg="2"
										cols="12"
										style="display: grid; grid-template-columns: repeat(2, 1fr)"
										class="pl-0 my-auto py-0"
									>
										<!-- <v-btn
															:disabled="files.length < 2"
															v-on:click="removeFile(index)"
															color="red lighten-1 white--text px-0"
															class="mt-3"
															icon
														> -->
										<v-icon
											:disabled="files.length < 2"
											@click="removeFile(index)"
											color="red lighten-1 white--text px-0"
											class="ml-1"
											>mdi-delete
										</v-icon>
										<!-- </v-btn> -->
										<!-- <v-btn
															color="blue darken-4 white--text"
															class="mt-3"
															depressed
															v-on:click="addMore()"
														> -->
										<div>
											<v-icon
												v-if="index == files.length - 1"
												:disabled="files.length > 4"
												color="blue darken-4 white--text"
												:style="{
													border: `1px solid ${files.length > 4 ? 'rgba(0, 0, 0, 0.38)' : '#0d47a1'}`,
													borderRadius: '50px',
												}"
												class="ml-3"
												depressed
												v-on:click="addMore()"
												>mdi-plus
											</v-icon>
										</div>
										<!-- </v-btn> -->
									</v-col>
									<!-- <v-col lg="1" cols="1" class="my-auto py-0">
													</v-col> -->
								</v-row>
							</template>
						</v-col>
					</v-row>
				</v-form>
			</v-layout>
		</template>
		<template v-slot:action>
			<v-btn
				class="white--text"
				:loading="pageLoading"
				:disabled="pageLoading || !formValid"
				depressed
				color="blue darken-4"
				tile
				@click="submit"
			>
				Renew
			</v-btn>
			<v-btn depressed tile v-on:click="closeDialog">Close </v-btn>
		</template>
	</Dialog>
</template>

<script>
import { GET, POST } from "@/core/services/store/request.module";
import { SET_MESSAGE } from "@/core/services/store/common.module";
import DatePicker from "@/view/components/DatePicker";
/* import SelectInput from "@/view/components/SelectInput"; */
import NumberInput from "@/view/components/NumberInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import Dialog from "@/view/components/Dialog";
import MomentJS from "moment-timezone";
import objectPath from "object-path";
/* import { filter } from "lodash"; */
import TextInput from "@/view/components/TextInput";
import MainMixin from "@/core/mixins/main.mixin.js";
export default {
	mixins: [MainMixin],
	name: "Update-SEOProject",
	title: "Update SEO Project",
	props: {
		serverDialog: {
			type: Boolean,
			default: false,
		},
		serverId: {
			type: [String, Number],
			default: null,
		},
	},
	components: {
		TextAreaInput,
		DatePicker,
		Dialog,
		TextInput,
		/* SelectInput, */
		NumberInput,
	},
	watch: {
		serverId: {
			immediate: true,
			handler(id) {
				if (id) {
					this.getServer(id);
				}
			},
		},
	},
	data() {
		return {
			pageLoading: false,
			dialog_width: null,
			minRenewDate: null,
			server: {
				cost: null,
				renew_date: null,
				payment_date: null,
				remark: null,
			},
			files: [
				{
					file: null,
					name: null,
				},
			],
			formValid: true,
		};
	},
	methods: {
		hasKey(object, key) {
			return objectPath.has(object, key);
		},
		getValue(object, key) {
			return objectPath.get(object, key);
		},
		goBack() {
			this.$router.go(-1);
		},
		addMore() {
			this.files.push({
				file: null,
				name: null,
			});
		},
		removeFile(index) {
			this.files.splice(index, 1);
		},
		updateFile(index, file) {
			if (file && file.name) {
				this.files[index].name = file.name.split(".").slice(0, -1).join(".");
				this.files[index].suffix = `.${file.name.split(".").pop()}`;
			} else {
				this.files[index].name = null;
				this.files[index].suffix = null;
			}
		},
		submit() {
			let formData = new FormData();

			formData.append("server_id", this.serverId);

			if (objectPath.get(this.server, "renew_date")) {
				formData.append("renew_date", this.server.renew_date);
			}
			if (objectPath.get(this.server, "payment_date")) {
				formData.append("payment_date", this.server.payment_date);
			}
			if (objectPath.get(this.server, "remark")) {
				formData.append("remark", this.server.remark);
			}
			if (objectPath.get(this.server, "cost")) {
				formData.append("cost", this.server.cost);
			}
			if (this.files.length && this.files[0].file) {
				this.files.forEach((file, index) => {
					formData.append(`file[${index}].file`, file.file);
					formData.append(`file[${index}].name`, file.name);
				});
			}

			this.$store
				.dispatch(POST, {
					url: `server-renew`,
					data: formData,
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message: "Success ! Server renew successfully.",
						},
					]);
					this.$emit("close", true);
					this.$emit("success", true);
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		getServer(id = this.serverId) {
			this.pageLoading = true;
			const _this = this;
			_this.$store
				.dispatch(GET, {
					url: `server/${id}`,
				})
				.then((data) => {
					let renew_d = MomentJS(data.renewal_date, "YYYY-MM-DD");

					if (data.renewal_basis && data.renewal_basis == "monthly") {
						renew_d.add(1, "months");
					} else if (data.renewal_basis && data.renewal_basis == "yearly") {
						renew_d.add(12, "months");
					}

					this.minRenewDate = renew_d.format("YYYY-MM-DD");

					this.server.payment_date = MomentJS(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD");
					this.server.cost = data.cost || null;

					this.server.renew_date = renew_d.format("YYYY-MM-DD");

					// this.server.remark = data.remark ? data.remark.replaceAll("\r", "") : null;
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		closeDialog() {
			this.$emit("close");
		},
	},
	mounted() {
		let screenWidth = document.body.clientWidth;
		let dialog_width = (screenWidth / 100) * 40;
		this.dialog_width = dialog_width;
	},
};
</script>
